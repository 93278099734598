*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.box {
  padding: 1em;
}

.box-scroll {
  padding: 1em 0 1em 1em;
}

input {
  width: 100%;
  padding: 1em;
  outline: none;
  border: 1px solid #f2f2f2;
}

.message-list {
  margin: 0;
  padding: 0;
}

.message-list li {
  padding: .2em;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: #f2f2f2;
}

/* Our Grid Code */

.app-layout {
  display: grid;
  height: 100vh;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto auto 1fr auto;
}

.appbar     { background-color: #112044; }
.channels   { background-color: #1b2a4e; }
.write      { background-color: #f2f2f2; }

.appbar {
  grid-column: 1 / 3;
  grid-row: 1 ;
}

.channels {
  grid-column: 1;
  grid-row: 2 / 5;
  overflow-y: auto;
  padding-right: 0;
  padding-left: 0;
}

.header-panel {
  grid-column: 2;
  grid-row: 2;
  color: #333;
  border-bottom: 1px solid #f2f2f2;
}

.messages {
  grid-column: 2;
  grid-row: 3;
  overflow-y: auto;
}

.write {
  grid-column: 2;
  grid-row: 4;
}

.participant {
  border-radius: 0px;
  display: inline-block;
}

video {
  width: 100%;
  object-fit: contain;
  max-width: 600px;
  display: block;
  margin: 0 auto;
}

.member-app-layout {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr auto;
}

.member-contents-1 {
  grid-column: 1;
  grid-row: 1;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-contents-2 {
  grid-column: 1;
  grid-row: 2;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-action {
  grid-column: 1;
  grid-row: 3;
}

.ui.mini.search.search-box > .ui.left.icon.input > input {
  color: #fff;
  background-color: #1b2a4e;
  width: 300px;
}

.ui.mini.search.search-box > .ui.left.icon.input > i.icon {
  color: #fff;
}
